<script setup lang="ts">
import {useViewport} from "~/composables/viewport";

const { activeBreakpoint } = useViewport()
</script>

<template>
  <div class="flex w-full items-center justify-center px-4 min-h-8 bg-gray-400">
    Active breakpoint: {{activeBreakpoint}}
  </div>

</template>