<template>
  <div v-if="loading || verificationInProgress" class="center">
    <Loader class="py-6" />
    <span>{{ t('BANKID_VERIFICATION_IN_PROGRESS') }}</span>
  </div>
	<div v-else>

		<p>{{ t('verifyUser.BANKID_PAGE_SUBTITLE') }}</p>

		<GMCollapse v-slot="{ isExpanded, toggle }">

			<button
				class="group flex py-2 sm:w-96 focus:outline-none text-blue-600 font-bold text-lg"
				aria-controls="expandCollapse3"
				:aria-expanded="isExpanded.toString()"
				@click="toggle"
			>{{ t('verifyUser.WHY_VERIFY') }}</button>

			<GMCollapseContent id="expandCollapse3" class="text-gray-700 dark:text-gray-300">
				<ul class="p-4 rounded bg-gray-300 list-disc">
					<li class="ml-2">{{ t('verifyUser.WHY_VERIFY_1') }}</li>
					<li class="ml-2">{{ t('verifyUser.WHY_VERIFY_2') }}</li>
					<li class="ml-2">{{ t('verifyUser.WHY_VERIFY_3') }}</li>
				</ul>
			</GMCollapseContent>
		</GMCollapse>

		<template v-if="!isForcedToVerify">
			<p class="my-3">{{ t('verifyUser.YOU_CAN_POSTPONE_VERIFICATION', {n: numberOfDaysToPostpone}) }}</p>
			<GMAlert class="mb-3" type="info" icon="information-circle">{{ t('verifyUser.MUST_VERIFY_WITHIN', {date: mustBeVerifiedByTheEndOfDateFormatted}) }}</GMAlert>
		</template>
		<GMAlert v-else class="my-3" type="warning" icon="exclamation">
			<p>{{ t('verifyUser.MUST_VERIFY') }}</p>
			<span>{{ t('verifyUser.MUST_VERIFY_HELP') }}</span>
			<SupportContactInformationBox />
		</GMAlert>

		<div class="flex flex-col items-stretch gap-2">
      <Button
          :disabled="loading"
          :icon-before="loading ? 'in-progress' : undefined"
          :spin-icon="loading"
          class="justify-center"
          @click="startVerification"
      >
        {{ t('verifyUser.START_VERIFICATION') }}
      </Button>
			<Button v-if="!isForcedToVerify" weight="tertiary" class="justify-center" @click="$emit('postpone-verification-click')">{{ t('verifyUser.POSTPONE_VERIFICATION') }}</Button>
		</div>
	</div>
</template>

<script lang="ts">
import {GMAlert, GMCollapse, GMCollapseContent} from '@gm/components';
import Button from '~/components/buttons/Button.vue';
import SupportContactInformationBox from '~/components/modal/VerifyAccount/SupportContactInformationBox.vue';
import {useBankIDStore} from "~/stores/bankID";

export default defineComponent({
		components: {
			SupportContactInformationBox,
			Button,
			GMCollapse,
			GMCollapseContent,
			GMAlert,
		},
		props: {
			isForcedToVerify: {
				type: Boolean,
				default: () => false
			},
			mustBeVerifiedByTheEndOfDateFormatted: {
				type: String,
				default: () => ''
			},
			numberOfDaysToPostpone: {
				type: Number,
				default: () => 3
			}
		},

		emits: ['go-next'],
		setup(_, { emit }) {
      const { t } = useTranslation()
      const bankIDStore = useBankIDStore()
			const router = useRouter()
			const route = useRoute()
      const loading = ref(false)

			onMounted(async () => {
				if (route.query.startVerification === '1' && bankIDStore.isLoggedIn) {
					await router.replace({ path: route.path, query: {...route.query, startVerification: undefined} })
          loading.value = true
          await bankIDStore.syncBankIDInformation()
          loading.value = false
					emit('go-next')
				}
			})

			const startVerification = async () => {
				if (bankIDStore.isLoggedIn) {
          loading.value = true
          await bankIDStore.syncBankIDInformation()
          loading.value = false
					emit('go-next')
				} else {
					await router.replace({ path: router.currentRoute.value.path, query: {startVerification: '1'} })
					location.href = "/bankid/login"
				}
			}

      const verificationInProgress = computed(() =>  {
        return bankIDStore.verificationInProgress;
      })

			return {
        t,
				startVerification,
        verificationInProgress,
        loading,
			}
		}
	})
</script>
