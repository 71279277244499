<script setup lang="ts">

import type {PropType} from "@vue/runtime-core";

const props = defineProps({
  color: {
    type: String as PropType<'yellow' | 'green' | 'red' | 'blue'>,
    default: 'yellow'
  }
})

const { t } = useTranslation()


</script>

<template>
  <div class="flex w-full items-center justify-center px-4 min-h-8"
       :class="{
			'bg-yellow-200 text-yellow-600': color === 'yellow',
			'bg-red-200 text-red-600': color === 'red',
			'bg-green-200 text-green-600': color === 'green',
			'bg-blue-300 text-blue-700': color === 'blue',
		}">
    <div v-html="$config.public.topInfoBannerText || t('WARNING_MAINTENANCE_TOP_BANNER')" />
  </div>

</template>