<template>
	<div>
		<template v-if="isFetchingPrimaryForestOwnerAccount">
			<Loader class="py-6"/>
		</template>
		<template v-else-if="bankidErrors && bankidErrors.length">
			<GMAlert type="error">
				<ul>
					<li v-for="err of bankidErrors" :key="err">
						<span v-if="te(err)" v-html="t(err)" />
					</li>
				</ul>
				<Button weight="primary" @click="loginToBankIdAgain">{{ t('LOG_IN') }}</Button>
			</GMAlert>
		</template>
		<template v-else>
			<div class="grid grid-cols-1 gap-4">
				<p class="my-3 text-lg">{{ t('verifyUser.IS_INFORMATION_CORRECT') }}</p>
				<div class="bg-gray-300 p-4 relative pb-10">
					<h3 class="text-gray-600 mb-2 text-lg font-semibold">{{ t('verifyUser.ABOUT_COMPANY') }}</h3>
					<dl>
						<template v-if="organisation">
							<dt class="font-bold text-gray-500 text-sm uppercase">{{ t('COMPANY_NAME') }}</dt>
							<dd class="mb-4 text-xl">
								<div class="flex flex-col">
									<div>{{ organisation.OrganisationName }}</div>
									<div class="text-sm">{{ t('ORGANIZATION_NUMBER') }}: {{ organisation.OrganisationNumber }}</div>
								</div>
							</dd>

							<dt class="font-bold text-gray-500 text-sm uppercase">{{ t('ORGANIZATION_ADDRESS') }}</dt>
							<dd class="mb-4 text-xl">
								{{organisation.OrganisationAddress}}
							</dd>

						</template>

						<dt class="font-bold text-gray-500 text-sm uppercase">{{ t('EMAIL_ADDRESS') }}</dt>
						<dd class="mb-4 text-xl">
							<div v-if="editingEmail">
								<input
									v-model="accountDetails.Email"
									class="input-text h-10 w-full"
									:class="{
										'input-text--error border-red-darker':
											!!emailError,
									}"
									type="email"
									autocomplete="email"
									required
									@blur="validateEmailInput($event.target?.value)"
									@input="emailError = ''"
								/>
								<ul v-if="emailError">
									<li class="flex mt-1 py-2 px-3 w-full bg-red-lightest text-red-darkest rounded">
										<svg-icon class="flex-shrink-0 mt-1 mr-1 w-4 h-4 fill-current text-red-lighter" name="error"/>
										<span>
											{{ emailError }}
										</span>
									</li>
								</ul>
							</div>
							<div v-else class="flex gap-4">
								<div v-if="emailAddress">{{ emailAddress }}</div>
								<button
									v-if="canEditAccount"
									class="change-btn"
									type="button"
									@click.stop.prevent="editingEmail = true"
								>
									{{ t('CHANGE') }}
									<span class="sr-only">
										{{ t('MOBILE_PHONE_NUMBER') }}
									</span>
								</button>
							</div>
						</dd>

						<dt class="font-bold text-gray-500 text-sm uppercase">{{ t('TELEPHONE_NUMBER') }}</dt>
						<dd class="mb-4 text-xl">
							<div v-if="editingMobile">
								<InputPhoneNumber
									v-model="mobilePhoneAsObject"
									@input="mobileError = ''"
									@blur="validateMobileInput($event.target?.value)"
								/>
								<ul v-if="mobileError">
									<li class="flex mt-1 py-2 px-3 w-full bg-red-lightest text-red-darkest rounded">
										<svg-icon class="flex-shrink-0 mt-1 mr-1 w-4 h-4 fill-current text-red-lighter" name="error"/>
										<span>
											{{ mobileError }}
										</span>
									</li>
								</ul>
							</div>
							<div v-else class="flex gap-4">
								<div v-if="mobilePhone">{{ mobilePhone }}</div>
								<button
									v-if="canEditAccount"
									class="change-btn"
									type="button"
									@click.stop.prevent="editingMobile = true"
								>
									{{ t('CHANGE') }}
									<span class="sr-only">
										{{ t('MOBILE_PHONE_NUMBER') }}
									</span>
								</button>
							</div>
						</dd>

						<template v-if="accountDetails">
							<dt class="font-bold text-gray-500 text-sm uppercase">{{ t('BANK_ACCOUNT_NUMBER') }}</dt>
							<dd class="mb-4 text-xl">
								<div v-if="editingBankAccount">
									<input
										v-model="accountDetails.BankAccountNumber"
										class="input-text h-10 w-36"
										:class="{
										'input-text--error border-red-darker':
											!!bankAccountError,
										}"
										type="text"
										inputmode="numeric"
										data-lpignore="true"
										autocomplete="false"
										required
										@blur="validateBankInput($event.target?.value)"
										@input="bankAccountError = ''"
									/>
									<ul v-if="bankAccountError">
										<li class="flex mt-1 py-2 px-3 w-full bg-red-lightest text-red-darkest rounded">
											<svg-icon class="flex-shrink-0 mt-1 mr-1 w-4 h-4 fill-current text-red-lighter" name="error"/>
											<span>
											{{ bankAccountError }}
										</span>
										</li>
									</ul>
								</div>
								<div v-else class="flex gap-4">
									<div
										:class="{
												'italic text-gray-700': !accountDetails.BankAccountNumber,
											}">
										{{
											accountDetails.BankAccountNumber
												? formatBankAccountNumber(accountDetails.BankAccountNumber)
												: t('NOT_REGISTERED')
										}}
									</div>
									<button
										v-if="canEditBankAccountNumber"
										class="change-btn"
										type="button"
										@click.stop.prevent="editingBankAccount = true"
									>
										{{ t('CHANGE') }}
										<span class="sr-only">
										{{ t('BANK_ACCOUNT_NUMBER') }}
									</span>
									</button>
								</div>
							</dd>

							<dt class="font-bold text-gray-500 text-sm uppercase">{{ t('ACCOUNTANT') }}</dt>
							<dd class="mb-4 text-xl">
								<div class="flex gap-4">
									<div
										v-if="accountDetails.AccountantName"
										class="mr-2 text-xl leading-6 flex-1"
									>
										{{ accountDetails.AccountantName }}
									</div>
									<button
										v-if="canEditAccount"
										class="change-btn"
										type="button"
										@click.stop.prevent="showAccountantsModal = true"
									>
										{{ accountDetails.AccountantId ? t('REPLACE') : t('ADD') }}
										<span class="sr-only">
												{{ t('ACCOUNTANT') }}
											</span>
									</button>
									<button
										v-if="canEditAccount && accountDetails.AccountantId"
										class="change-btn border-red-300 text-red-500 hocus:border-red-lighter"
										type="button"
										@click.stop.prevent="removeAccountant"
									>
										{{ t('REMOVE') }}
										<span class="sr-only">
											{{ t('ACCOUNTANT') }}
										</span>
									</button>
								</div>
							</dd>
						</template>


						<Button v-if="hasChanges" weight="tertiary" class="absolute bottom-0" @click="resetChanges">
							<span>{{ t('RESET_CHANGES') }}</span>
						</Button>
					</dl>
				</div>

			</div>

			<GMAlert v-if="!canEditAccount && !loadingIfUserCanEditAccountOrNot" type="warning" class="my-4">
				<template v-if="nameOfPersonThatCanEdit">
					{{ t('verifyUser.CHANGE_ACCOUNT_CONTACT_NAME', { name: nameOfPersonThatCanEdit }) }}
				</template>
				<template v-else>
					{{ t('verifyUser.CHANGE_ACCOUNT_CONTACT_SUPPORT') }} <SupportContactInformationBox />
				</template>
			</GMAlert>
			<GMAlert v-if="canEditAccount && !canEditBankAccountNumber && !loadingIfUserCanEditAccountOrNot" type="warning" class="my-4">
				{{ t('CHANGE_BANK_ACCOUNT_CONTACT_SUPPORT') }} <SupportContactInformationBox />
			</GMAlert>

			<GMAlert type="info" class="mt-4">
				<div class="text-sm">
					{{ t('verifyUser.PRIVACY_POLICY_DISCLAIMER_1') }}
					<GMCollapse v-slot="{ isExpanded, toggle }">
						<button
							class="group flex py-2 sm:w-96 focus:outline-none text-blue-600"
							aria-controls="expandCollapse-privacy"
							:aria-expanded="isExpanded.toString()"
							@click="toggle"
						>{{ t(isExpanded ? 'SHOW_LESS' : 'SHOW_MORE') }}</button>

						<GMCollapseContent id="expandCollapse-privacy" class="text-gray-700 dark:text-gray-300">
							{{ t('verifyUser.PRIVACY_POLICY_DISCLAIMER_2') }}
						</GMCollapseContent>

					</GMCollapse>
				</div>
				<button
					class="text-blue-700 font-bold underline mt-2"
					@click.prevent="showPrivacyPolicy"
				>
					{{ t('SHOW_PRIVACY_INFORMATION_STATEMENT') }}
				</button>
			</GMAlert>

			<div class="flex flex-col items-stretch mt-4">
				<Button
					:disabled="submitInProgress || !formValid"
					class="justify-center col-span-2"
					:icon-before="submitInProgress ? 'in-progress' : 'check'"
					:spin-icon="submitInProgress"
					@click.stop.prevent="approve"
				>
					<template v-if="hasChanges">
						{{ t('CONFIRM_CHANGES_AND_SAVE') }}
					</template>
					<template v-else>
						{{ submitInProgress ? t('BANKID_APPROVAL_IN_PROGRESS') : t('CONFIRM') }}
					</template>
				</Button>
				<Button class="justify-center col-span-2" weight="tertiary" @click="$emit('incorrect-information-clicked')">
					{{ t('verifyUser.NO_INFO_IS_NOT_CORRECT') }}
				</Button>

				<div v-if="syncError" class="my-3 text-red">
					{{t(syncError)}}
				</div>
			</div>
		</template>
		<ModalChangeAccountant
			v-if="accountDetails"
			:show="showAccountantsModal"
			:active-accountant-id="accountDetails?.AccountantId"
			:emit-changes="true"
			@update:modelValue="changeAccountant"
			@close="showAccountantsModal = false"
		/>
	</div>
</template>

<script lang="ts">
import {GMAlert, GMCollapse, GMCollapseContent, GMIcon} from '@gm/components'
import cloneDeep from 'lodash/cloneDeep'
import Button from '~/components/buttons/Button.vue';
import {AccountApi, type AccountModel, BankIdApi, RightOfUseApi, UserApi,} from '~/gen/openapi/portalService';
import validateEmailAddress from '~/helpers/validateEmailAddress';
import validatePhoneNumber from '~/helpers/validatePhoneNumber';
import formatAddress from '~/helpers/format-address';
import formatBankAccountNumber from '~/helpers/formatBankAccountNumber';
import validateBankAccountNumber from '~/helpers/validateBankAccountNumber'
import SupportContactInformationBox from '~/components/modal/VerifyAccount/SupportContactInformationBox.vue';
import {getCanEditAccountChecker} from '~/helpers/canEditAccountChecker';
import {isFakeOrgNumber} from '~/helpers/organisationHelper';
import InputPhoneNumber from '~/components/form/InputPhoneNumber.vue';
import {usePrivacyInformationStore} from '~/stores/privacyInformation';
import {useBankIDStore} from "~/stores/bankID";
import {useDelegationOfAuthorityStore} from "~/stores/delegationOfAuthority";
import type { PhoneNumberType } from '~/components/form/models';
import {useAccountService} from "~/services/account";

/**
 * Step for verifying account details.
 * To make sure it is the rightful user that can make changes to a forest owner account (usually an ENK company),
 * we are using some BRREG API's.
 *
 * @see {getCanEditAccountChecker} for details on rules for editing the account
 */
export default defineComponent({
	components: {
		SupportContactInformationBox,
		GMAlert,
		GMIcon,
		Button,
		GMCollapse,
		GMCollapseContent,
		InputPhoneNumber,
	},
	setup(_, {emit}) {
    const { primaryForestOwnerAccountQuery, primaryForestOwnerAccountPermissionModel, accountsQuery, saveAccount, isPendingSaveAccount } = useAccountService();
    const { data: accounts } = accountsQuery()
    const permissionModel = primaryForestOwnerAccountPermissionModel(accounts)
    const { data: primaryForestOwnerAccount, isFetching: isFetchingPrimaryForestOwnerAccount } = primaryForestOwnerAccountQuery(permissionModel)
    const delegationOfAuthorityStore = useDelegationOfAuthorityStore()
    const bankIDStore = useBankIDStore();
		const privacyInformationStore = usePrivacyInformationStore()
		const router = useRouter();
		const {$axios, $config} = useNuxtApp()
		const bankidApi = new BankIdApi(undefined, $config.public.apiBaseHost, $axios)
		const userApi = new UserApi(undefined, $config.public.apiBaseHost, $axios)
		const canEditAccountChecker = getCanEditAccountChecker(userApi, bankidApi)
		const rightOfUseApi = new RightOfUseApi(undefined, $config.public.apiBaseHost, $axios)
		const accountDetails = ref<AccountModel>()
		const accountDetailsOld = ref<AccountModel>()
		const {t, te} = useTranslation()
		const editingEmail = ref(false)
		const emailError = ref<string>()
		const editingMobile = ref(false)
		const mobilePhoneAsObject = computed({
			get() {
				if (accountDetails.value?.Mobile?.substring(0, 1) === '+') {
					return {
						phoneNumber: accountDetails.value?.Mobile.substring(3),
						countryCode: accountDetails.value?.Mobile.substring(0, 3),
					}
				} else {
					return {
						phoneNumber: accountDetails.value?.Mobile || '',
						countryCode: '+47',
					}
				}
			},
			set(value: PhoneNumberType) {
				if (accountDetails.value) {
					accountDetails.value.Mobile = value.countryCode + value.phoneNumber
				}
			},
		})
		const mobileError = ref<string>()
		const editingBankAccount = ref(false)
		const bankAccountError = ref<string>()
		const syncError = ref('')
		const bankidErrors = computed(() => bankIDStore.errors)
		const organisation = computed(() => {
			if (!accountDetails.value) {
				return null
			}
			if (!accountDetails.value.OrganisationNumber || isFakeOrgNumber(accountDetails.value.OrganisationNumber)) {
				// Fake organisation, do not display
				return null
			}
			return {
				OrganisationNumber: accountDetails.value.OrganisationNumber,
				OrganisationName: accountDetails.value.Name,
				OrganisationAddress: formatAddress({
					Line1: accountDetails.value.Address,
					Line2: accountDetails.value.Address2,
					PostalCode: accountDetails.value.PostalCode,
					City: accountDetails.value.City,
				})
			}
		})
		const loadingIfUserCanEditAccountOrNot = ref(true)
		const canEditAccount = ref(false)
		const canEditBankAccountNumber = ref(false)
		const nameOfPersonThatCanEdit = ref<string>()

		const formValid = computed(() => {
			return !bankAccountError.value && !mobileError.value && !emailError.value
		})

		async function getAccountDetails() {
      accountDetailsOld.value = cloneDeep(primaryForestOwnerAccount.value)

			const orgNumber = accountDetails.value?.OrganisationNumber
			if (orgNumber) {
				loadingIfUserCanEditAccountOrNot.value = true
				const {canEdit, personThatCanEdit, canEditBankAccountNumber: canEditBank} = await canEditAccountChecker(orgNumber)
				loadingIfUserCanEditAccountOrNot.value = false
				canEditAccount.value = canEdit
				canEditBankAccountNumber.value = canEditBank
				nameOfPersonThatCanEdit.value = personThatCanEdit
			}
		}

		onMounted(() => {
      getAccountDetails()
		})

		const emailAddress = computed(() => accountDetails.value?.Email)
		const mobilePhone = computed(() => accountDetails.value?.Mobile)

		function validateBankInput(number?: string) {
			if (!!number && !validateBankAccountNumber(number)) {
				bankAccountError.value = t('ERROR_INCORRECT_BANK_ACCOUNT_FORMAT').toString()
			} else {
				bankAccountError.value = ''
			}
		}

		function validateEmailInput(email?: string) {
			if (!validateEmailAddress(email)) {
				emailError.value = t('ERROR_INVALID_EMAIL').toString()
			} else {
				emailError.value = ''
			}
		}

		function validateMobileInput(mobile?: string) {
			if (!validatePhoneNumber(mobile)) {
				mobileError.value = t('ERROR_INVALID_PHONE_NUMBER').toString()
			} else {
				mobileError.value = ''
			}
		}

		const approve = async () => {

			if (!formValid.value) {
				return
			}

			syncError.value = ''

      const accountId = accountDetails.value?.Id || accountDetailsOld.value?.Id
      if (!accountId) {
        syncError.value = 'ERROR_GENERIC'
        console.error('Missing account ID when confirming account details')
      }

			if (accountId) {
        const accountObj = hasChanges.value ? accountDetails.value : accountDetailsOld.value
        saveAccount({
          Account: {Id: accountId, ...accountObj},
          AccessToken: bankIDStore.tokens?.access_token,
          IdToken: bankIDStore.tokens?.id_token
        }, {
          onError: (error) => {
            syncError.value = 'ERROR_GENERIC'
            console.error(error)
          },
          onSettled: async () => {
            try {
              // Ignore errors if setting right of use automatically failed. User is still verified, and can continue.
              try {
                await rightOfUseApi.rightOfUseSetRightOfUseAndPowerOfAttorneyAutomatically()
                delegationOfAuthorityStore.reset()
              } catch (e) {
                console.error('Error setting right of use automatically', e)
              }

              emit('go-next')
            } catch (e) {
              console.error('Could not confirm account details', e)
              syncError.value = 'ERROR_GENERIC'
            }
          }})
      }
    }

		const submitInProgress = computed(
			() => isPendingSaveAccount.value || bankIDStore.approvalInProgress
		)

		const hasChanges = computed(() => JSON.stringify(accountDetails.value) !== JSON.stringify(accountDetailsOld.value))
		const resetChanges = () => {
			accountDetails.value = cloneDeep(accountDetailsOld.value)
			editingEmail.value = false
			editingMobile.value = false
			editingBankAccount.value = false

			validateEmailInput(accountDetails.value?.Email)
			validateMobileInput(accountDetails.value?.Mobile)
			validateBankInput(accountDetails.value?.BankAccountNumber)
		}

		const showAccountantsModal = ref(false)
		const changeAccountant = (data: any) => {
			if (accountDetails.value) {
				if ('AccountantId' in data) {
					accountDetails.value.AccountantId = data.AccountantId
				}
				if ('AccountantName' in data) {
					accountDetails.value.AccountantName = data.AccountantName
				}
			}
		}
		const removeAccountant = () => {
			if (accountDetails.value) {
				accountDetails.value.AccountantId = undefined
				accountDetails.value.AccountantName = undefined
			}
		}

		const loginToBankIdAgain = async () => {
			await router.replace({ path: router.currentRoute.value.path, query: {startVerification: '1'} })
			location.href = "/bankid/login"
		}

		const showPrivacyPolicy = () => {
			privacyInformationStore.show()
		}

		return {
      t,
      te,
			approve,
			submitInProgress,
			accountDetails,
      primaryForestOwnerAccount,
      isFetchingPrimaryForestOwnerAccount,
			emailAddress,
			mobilePhone,
			editingEmail,
			editingMobile,
			hasChanges,
			emailError,
			resetChanges,
			mobileError,
			formatBankAccountNumber,
			showAccountantsModal,
			changeAccountant,
			removeAccountant,
			syncError,
			organisation,
			editingBankAccount,
			bankAccountError,
			formValid,
			validateBankInput,
			validateEmailInput,
			validateMobileInput,
			canEditAccount,
			canEditBankAccountNumber,
			mobilePhoneAsObject,
			loadingIfUserCanEditAccountOrNot,
			nameOfPersonThatCanEdit,
			bankidErrors,
			loginToBankIdAgain,
			showPrivacyPolicy,
      delegationOfAuthorityStore,
		}
	}
})
</script>

<style scoped>
.change-btn {
	@apply transition duration-300 inline-flex items-center border-2 border-blue-300 hocus:border-blue-lighter px-4 py-4 /*bg-blue-lightest*/ h-6 text-xs text-blue-darkest /*font-semibold*/ leading-3 uppercase /*rounded*/ hocus:shadow !important;
}
</style>
